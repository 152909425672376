<template>
  <q-select
    ref="select"
    v-bind="$attrs"
    use-input
    fill-input
    hide-selected
    hide-dropdown-icon
    input-class="q-pl-sm"
    class="text-blue-grey-6"
    :options="options"
    :loading="loading"
    behavior="menu"
    :rounded="$q.platform.is.mobile"
    :outlined="$q.platform.is.mobile"
    @input="input"
    @filter="filter"
    @click.native="click"
  >
    <template v-if="smartSearch" v-slot:after-options>
      <saved-and-recent :style="{maxWidth: maxWidth}" @selected="input" @close="() => {options = []}" />
    </template>
    <template v-if="icon" v-slot:before>
      <q-icon :name="icon" color="primary" />
    </template>
    <template v-slot:option="scope">
      <q-item :style="{maxWidth: maxWidth}" v-bind="scope.itemProps" v-on="scope.itemEvents">
        <q-item-section v-if="scope.opt.icon" avatar>
          <q-icon :color="scope.opt.color || `black-secondary`" :name="scope.opt.icon" />
        </q-item-section>
        <q-item-section v-else-if="scope.opt.avatar" avatar>
          <q-avatar>
            <m-image :src="scope.opt.avatar" :placeholder-src="require('assets/avatar.png')" />
          </q-avatar>
        </q-item-section>
        <q-item-section>
          <q-item-label :style="{overflowWrap: 'break-word'}" v-html="scope.opt.label" />
          <q-item-label caption class="ellipsis" v-html="scope.opt.description" />
        </q-item-section>
        <q-item-section v-if="scope.opt.append" side>
          <q-item-label v-html="scope.opt.append" />
        </q-item-section>
      </q-item>
    </template>
  </q-select>
</template>

<script>
import SavedAndRecent from 'components/MLocationSearch/saved-and-recent.vue'
import { mapGetters } from 'vuex'
import { MImage } from 'components/'

export default {
  components: { SavedAndRecent, MImage },
  inheritAttrs: false,

  props: {
    icon: String,
    query: {
      type: Function
    },
    smartSearch: Boolean
  },
  data () {
    return {
      options: [],
      loading: false,
      maxWidth: 'initial'
    }
  },
  computed: {
    ...mapGetters({
      geoip: 'geoip',
      latLng: 'geolocate/latLng'
    }),
    latitude () {
      return this.latLng.latitude || this.geoip.latitude
    },
    longitude () {
      return this.latLng.longitude || this.geoip.longitude
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.maxWidth = `${this.$el.offsetWidth}px`
    })
  },
  methods: {
    filter (value, update, abort) {
      if (value.length > 2) {
        this.loading = true
        this.query(value).then(response => {
          this.options = response
          this.$refs.select.setOptionIndex(0)
          this.$refs.select.updateMenuPosition()
          this.showSaved = false
          this.loading = false
          update()
        }).catch(() => {
          abort()
        })
      }

      abort()
    },
    input (value) {
      this.$emit('selected', value)
      this.$emit('input', value)
      this.options = []
    },
    click (event) {
      this.$emit('focus')
      this.$refs.select.$refs.target.select()
    }
  }
}
</script>
